import PropTypes from "prop-types"
import React from "react"
import "bootstrap-icons/font/bootstrap-icons.css";
import Button from "react-bootstrap/Button";

const ModalForDoorsButton = ({activeItem, onClick}) => (
    <Button variant={"primary"} onClick={onClick}>
        <i className="bi bi-cart3"/>&nbsp;
        Заказать
    </Button>

// <Button type={"button"} className={"btn btn-primary d-inline-flex"} data-toggle={"modal"}
//         data-target={"#showModal"}>
//   <i className="bi bi-cart3"/>&nbsp;
//   Заказать
// </Button>

)

ModalForDoorsButton.propTypes = {
    activeItem: PropTypes.string,
}

ModalForDoorsButton.defaultProps = {
    activeItem: ``,
}

export default ModalForDoorsButton
