import PropTypes from "prop-types"
import React from "react"
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

const ModalForDoors = ({activeItem, show, onHide}) => (
    <Modal show={show} onHide={onHide}>
        <Modal.Header>
            <h5 className="modal-title" id="exampleModalLabel">Перезвонить</h5>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group>
                    <label htmlFor="exampleInputName">Имя</label>
                    <input name={"name"} type="text" className="form-control" id="exampleInputName"/>
                </Form.Group>
                <Form.Group>
                    <label htmlFor="exampleInputPhone">Телефон</label>
                    <input name={"telephone"} type="phone" className="form-control" id="exampleInputPhone"/>
                </Form.Group>
                <Modal.Footer>
                    <Button type="button" variant="secondary" onClick={onHide}>Закрыть</Button>
                    <Button type="submit" variant="primary" onClick={onHide}>Заказать</Button>
                </Modal.Footer>
            </Form>
        </Modal.Body>
    </Modal>
)

ModalForDoors.propTypes = {
    activeItem: PropTypes.string,
}

ModalForDoors.defaultProps = {
    activeItem: ``,
}

export default ModalForDoors
